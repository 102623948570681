import React from "react";
import AppRouter from "./router/Router";
import CssBaseline from "@material-ui/core/CssBaseline";
import GlobalStyles from "./GlobalStyles";
import "./assets/scss/index.scss";
import SecuredApp from "./components/SecuredApp.js.js";

function App() {
  return (
    <div className="App">
      <CssBaseline />
      <GlobalStyles />
      <SecuredApp>
        <AppRouter />
      </SecuredApp>
    </div>
  );
}

export default App;
