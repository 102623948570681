import apiClient from "./apiClient";
import { SIGNIN_URL } from "./urlApi";

const signup = ({ email, first_name, last_name, password1, password2 }) => {
  const formData = new FormData();
  formData.append("email", email);
  formData.append("first_name", first_name);
  formData.append("last_name", last_name);
  formData.append("password1", password1);
  formData.append("password2", password2);
  return apiClient.post(SIGNIN_URL, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const signin = ({ email, password }) => {
  return apiClient.post(`/signin/`, { email, password });
};
const signinFake = ({ email, password }) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (password === "12345")
        resolve({
          status: 200,
          data: {
            email,
            name: "dragan",
            token:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c",
            profile: {
              firstName: "Dragan",
              lastName: "Mihajlov",
            },
          },
        });
      else reject(`Login faild !!!!`);
    }, 100);
  });
};

const authAPI = {
  signup,
  signin,
  signinFake,
};

export default authAPI;
