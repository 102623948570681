export const UnauthenticatedRoutes = [
  {
    exact: true,
    path: "/about",
    component: "About",
  },
  {
    exact: true,
    path: "/login",
    component: "Login",
  },
  {
    exact: true,
    path: "/",
    component: "Login",
  },
];

export const AuthenticatedRoutes = [
  {
    exact: true,
    path: "/projects",
    component: "Projects",
  },
  {
    exact: true,
    path: "/review",
    component: "Dashboard",
  },
];
